import React from "react"
import { useSelector } from "react-redux"
import { insertCustifyTag } from "shared/analytics/CustifyTag"
import { RootState } from "shared/redux/store"
import { log as logUtil } from "shared/util/log"
import { AnalyticsContext } from "./AnalyticsContext"
import { AnalyticsService, noopAnalyticsService } from "./AnalyticsService"
import { useUiConfigQuery } from "shared/uiconfig/useUiConfigQuery"
import { LightweightUserInfoDTO } from "generated/models"

const IGNORED_USERS: readonly string[] = ["OTTO-SLA-Login-Test"]

export interface CustifyAnalyticsContextProviderProps {
    children: React.ReactNode
}

export const CustifyAnalyticsContextProvider = ({ children }: CustifyAnalyticsContextProviderProps) => {
    // Step 1: Is the user logged in via first factor? We don't want to initialize Custify before that.
    const loggedInViaFirstFactor = useSelector((state: RootState) => state.authentication.loggedInViaFirstFactor)
    const skipUiConfigQuery = !loggedInViaFirstFactor

    // Step 2: Get the Custify config from the backend if the user is logged in. If Custify is disabled in the config, we don't need to initialize it.
    const uiConfig = useUiConfigQuery({ skip: skipUiConfigQuery })

    const userInfo = useSelector((state: RootState) => state.user?.userInfo)
    const appContext = useSelector((state: RootState) => state.appContext?.appContext)

    const [initializationRequested, setInitializationRequested] = React.useState<
        false | "withLoginEvent" | "withoutLoginEvent"
    >(false)
    const [dispatchIdentifyEvent, setDispatchIdentifyEvent] = React.useState(false)
    const [dispatchLoginEvent, setDispatchLoginEvent] = React.useState(false)

    // Only proceed if we have all the data we need
    if (!uiConfig.isSuccess || !userInfo || !appContext) {
        log("Not all data available, not initializing")
        return (
            // We don't know yet if Custify is enabled, but calls to initialize() might already be made, so we need to make sure we don't lose them.
            <AnalyticsContext.Provider
                value={{
                    async initialize(trackLoginEvent: boolean) {
                        setInitializationRequested(trackLoginEvent ? "withLoginEvent" : "withoutLoginEvent")
                    },
                    trackMenuNavigation(rootNode: string, path: string) {},
                    trackButtonClick(buttonName: string) {},
                    trackCustomEvent(eventName: string, eventData: object) {},
                    trackUserData(userData: object) {},
                    stopTracking() {},
                }}
            >
                {children}
            </AnalyticsContext.Provider>
        )
    }

    // If Custify is disabled, we provide a dummy AnalyticsService that does nothing
    if (!uiConfig.data.custifyEnabled) {
        log("Custify is disabled, not initializing")
        return <AnalyticsContext.Provider value={noopAnalyticsService}>{children}</AnalyticsContext.Provider>
    }

    // Step 3: Initialize Custify
    log("All data available, proceeding")
    const { custifyProductKey, custifyTrackInternalUsers } = uiConfig.data

    const isIgnoredUserName = IGNORED_USERS.includes(userInfo.loginName)
    const isIgnoredUser = isIgnoredUserName || (!custifyTrackInternalUsers && userInfo.internalUser)

    const initialize = async (trackLoginEvent = false) => {
        if (isInitialized()) {
            log("Already initialized, not initializing again")
            onAfterInitialize(trackLoginEvent)
            return
        }

        if (isIgnoredUser) {
            log("User is ignored, not initializing")
            return
        }

        log("Inserting Custify tag")
        insertCustifyTag(custifyProductKey)

        // Poll until _ctrack is available
        const pollForCustify = setInterval(() => {
            if (isInitialized()) {
                clearInterval(pollForCustify)
                log("Custify initialized, calling identify")
                onAfterInitialize(trackLoginEvent)
            }
        }, 100) // Poll every 100ms
    }

    const onAfterInitialize = (trackLoginEvent: boolean) => {
        setDispatchIdentifyEvent(true)
        if (trackLoginEvent) {
            setDispatchLoginEvent(true)
        }

        callCustifyTrackTime()
    }

    const isInitialized = () => Boolean((window as any)._ctrack)

    const identifyUser = async () => {
        return callCustifyIdentify(userInfo)
    }

    const trackLogin = async () => {
        return trackCustomEvent("Login", {})
    }

    const trackMenuNavigation = async (rootNode: string, path: string) => {
        trackCustomEvent("Select Menu", {
            rootNode: rootNode,
            menuPath: path,
        }).catch((err) => logUtil.debug("Failed to track menu navigation", err))

        await callCustifyTrackTime()
    }

    const trackButtonClick = async (buttonName: string) => {
        trackCustomEvent("Click Button", {
            buttonName: buttonName,
        }).catch((err) => logUtil.debug("Failed to track button click event", err))
    }

    const trackUserData = async (userData: { [key: string]: any }) => {
        if (isInitialized() && !isIgnoredUser) {
            return callCustifyIdentify(userInfo, userData)
        }
    }

    const stopTracking = async () => {
        await callCustifyStopTrackTime()
    }

    function getCurrentTimestamp(): string {
        try {
            const now = new Date()

            const year = now.getUTCFullYear()
            const month = String(now.getUTCMonth() + 1).padStart(2, "0")
            const day = String(now.getUTCDate()).padStart(2, "0")

            const hours = String(now.getUTCHours()).padStart(2, "0")
            const minutes = String(now.getUTCMinutes()).padStart(2, "0")
            const seconds = String(now.getUTCSeconds()).padStart(2, "0")

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
        } catch (e) {
            return ""
        }
    }

    const trackCustomEvent = async (eventName: string, eventData: { [key: string]: any } = {}) => {
        if (isInitialized() && !isIgnoredUser) {
            eventData.urlPath = getPath()
            return callCustifyTrack(eventName, eventData)
        }
    }

    const callCustifyIdentify = async (
        userInfo: LightweightUserInfoDTO,
        additionalCustomAttributes: {
            [key: string]: any
        } = {},
    ) => {
        log("Calling Custify identify()")

        window["_ctrack"].setAccount(custifyProductKey)
        window["_ctrack"].setOptions({ createOrUpdateEntities: true })

        // on staging systems all users have the same e-mail address, so we prefix it with the user ID
        const email = ((custifyTrackInternalUsers ? userInfo.id + "_" : "") + userInfo.email).toLowerCase()

        return window["_ctrack"].identify(
            {
                email: email,
                // we don't always have the agency ID in Hubspot when creating a new agency; the name is always available right from the start so it can be used as an identifier to connect all systems
                company_id: userInfo.agencyName,
            },
            {
                user: {
                    name: `${userInfo.firstName} ${userInfo.lastName}`,
                    email: email,
                    custom_attributes: {
                        exactagUserId: userInfo.id,
                        loginName: userInfo.loginName,
                        role: userInfo.role.roleName,
                        roleName: userInfo.role.roleName,
                        roleID: userInfo.role.roleId,
                        userCompany: userInfo.company,
                        signUpDate: userInfo.createdTs,
                        last_ui_interaction_at: getCurrentTimestamp(),
                        ...additionalCustomAttributes,
                    },
                },
            },
        )
    }

    function getPath(): string {
        let urlPath = window.location.pathname
        if (urlPath.startsWith("/ui/")) {
            urlPath = urlPath.substring(4)
        }
        return urlPath
    }

    const callCustifyTrackTime = async () => {
        log("Calling Custify trackTime()")
        return window["_ctrack"].trackTime(true, {
            module: getPath(),
        })
    }

    const callCustifyStopTrackTime = async () => {
        log("Calling Custify stopTrackTime()", module)
        return window["_ctrack"].stopTrackTime()
    }

    const callCustifyTrack = async (eventName: string, eventData: object) => {
        log("Calling Custify track()", eventName, eventData)

        // keep updating user data in addition to tracking the events so that we can set the last UI interaction time as a custom property for the user
        await identifyUser()

        return window["_ctrack"].track(eventName, eventData)
    }

    // If initialization was requested before we had all the data, we can now proceed
    if (initializationRequested) {
        log("Initialization requested, proceeding")
        const trackLoginEvent = initializationRequested === "withLoginEvent"
        initialize(trackLoginEvent)
        setInitializationRequested(false)
    }

    // Once everything is properly initialized, we can dispatch the identify and login events
    if (isInitialized() && !isIgnoredUser) {
        let identifyUserPromise = Promise.resolve()
        if (dispatchIdentifyEvent) {
            log("Dispatching identify event")
            identifyUserPromise = identifyUser()
            setDispatchIdentifyEvent(false)
        }
        if (dispatchLoginEvent) {
            log("Dispatching login event")
            identifyUserPromise.then(trackLogin)
            setDispatchLoginEvent(false)
        }
    }

    const analyticsService: AnalyticsService = {
        initialize,
        trackMenuNavigation,
        trackButtonClick,
        trackCustomEvent,
        trackUserData,
        stopTracking,
    }

    return <AnalyticsContext.Provider value={analyticsService}>{children}</AnalyticsContext.Provider>
}

export const log = (...msg) => {
    logUtil.info("[CustifyAnalyticsContextProvider] ", ...msg)
}
