import { Add } from "@mui/icons-material"
import {
    Box,
    Button,
    ButtonBase,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material"
import { WidgetMap } from "domain/widget/WidgetRenderer"
import React, { useState } from "react"
import { useDashboardLayout } from "./DashboardLayoutProvider"

const WidgetConfig = {
    "text-widget": {
        label: "Text widget",
        src: "/ui/widgets/text-widget.jpeg",
    },
    "multi-bar-chart": {
        label: "Multi Bar chart",
        src: "/ui/widgets/bar-chart.png",
    },
    "time-based-bar-chart": {
        label: "Time-based Bar chart",
        src: "/ui/widgets/bar-chart.png",
    },
    "line-chart": {
        label: "Line chart",
        src: "/ui/widgets/line-chart.png",
    },
    "tabbed-widget-chart": {
        label: "Tabbed widget",
        src: "/ui/widgets/tabbed-widget.png",
    },
    period: {
        label: "Period",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    "previous-period": {
        label: "Previous period",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    "metric-toggle": {
        label: "Metric toggle",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    "reporting-dimension-toggle": {
        label: "Reporting dimension toggle",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
    "reporting-method-toggle": {
        label: "Reporting method toggle",
        src: "/ui/widgets/widget-placeholder-image.png",
    },
} satisfies Record<keyof WidgetMap, any>

type WidgetItemProps<Type extends keyof WidgetMap> = {
    type: Type
    onClick: (type: Type) => void
}

const WidgetItem = <Type extends keyof WidgetMap>(props: WidgetItemProps<Type>) => {
    const { type, onClick } = props
    const { label, src } = WidgetConfig[type]

    return (
        <ButtonBase
            onClick={() => onClick(type)}
            sx={{
                display: "flex",
            }}
        >
            <Card
                elevation={1}
                sx={{
                    flex: 1,
                }}
            >
                <CardMedia sx={{ height: 140 }} image={src} />
                <CardContent
                    sx={{
                        p: 0,
                    }}
                >
                    <Typography gutterBottom variant="button" fontWeight={500} component="div">
                        {label}
                    </Typography>
                </CardContent>
            </Card>
        </ButtonBase>
    )
}

type AddWidgetModalProps = {
    enabledWidgets?: Array<keyof WidgetMap>
}

// TODO<Firat>: Where to get initial values?
const AddWidgetModal = (props: AddWidgetModalProps) => {
    const { enabledWidgets } = props
    const [open, setOpen] = useState(false)
    const { addWidget } = useDashboardLayout()

    const renderWidget = (type: keyof WidgetMap) => {
        return !enabledWidgets || enabledWidgets.includes(type)
    }

    return (
        <div>
            <Button variant="contained" onClick={() => setOpen(true)} endIcon={<Add />}>
                Add widget
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" PaperProps={{ sx: { width: "100%" } }}>
                <DialogTitle>Add widget</DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
                            rowGap: 2,
                            columnGap: 2,
                            px: 4,
                        }}
                    >
                        {renderWidget("text-widget") && (
                            <WidgetItem
                                type="text-widget"
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                htmlContent: undefined,
                                            },
                                        },
                                        {
                                            w: 6,
                                            h: 30,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget("multi-bar-chart") && (
                            <WidgetItem
                                type="multi-bar-chart"
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                orientation: "horizontal",
                                                primaryDimension: "device_type",
                                                secondaryDimensions: ["clicks"],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget("time-based-bar-chart") && (
                            <WidgetItem
                                type="time-based-bar-chart"
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                orientation: "horizontal",
                                                primaryDimension: "device_type",
                                                secondaryDimensions: ["clicks"],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget("line-chart") && (
                            <WidgetItem
                                type="line-chart"
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                primaryDimension: "daily",
                                                secondaryDimensions: [
                                                    "bounce_visits",
                                                    "quality_visits",
                                                    "failed_reach_visits",
                                                ],
                                            },
                                        },
                                        {
                                            w: 4,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {renderWidget("tabbed-widget-chart") && (
                            <WidgetItem
                                type="tabbed-widget-chart"
                                onClick={(type) => {
                                    addWidget(
                                        {
                                            type,
                                            settings: {
                                                tabs: [
                                                    {
                                                        metricIdentifier: "imps",
                                                        layout: [],
                                                    },
                                                    {
                                                        metricIdentifier: "clicks",
                                                        layout: [],
                                                    },
                                                    {
                                                        metricIdentifier: "contacts_totp",
                                                        layout: [],
                                                    },
                                                    {
                                                        metricIdentifier: "pi",
                                                        layout: [],
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            w: 6,
                                            h: 40,
                                        },
                                    )
                                    setOpen(false)
                                }}
                            />
                        )}
                        {(
                            [
                                "period",
                                "previous-period",
                                "metric-toggle",
                                "reporting-dimension-toggle",
                                "reporting-method-toggle",
                            ] as const
                        ).map(
                            (w) =>
                                renderWidget(w) && (
                                    <WidgetItem
                                        key={w}
                                        type={w}
                                        onClick={(type) => {
                                            addWidget(
                                                {
                                                    type,
                                                    settings: {},
                                                },
                                                {
                                                    w: 3,
                                                    h: 8,
                                                },
                                            )
                                            setOpen(false)
                                        }}
                                    />
                                ),
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddWidgetModal
