import { useQuery } from "@tanstack/react-query"
import { DashboardSettingsResponse } from "domain/../../stories/msw/handlers/dashboardSettings"
import { DimensionResponseExtendedDto } from "domain/../../stories/msw/handlers/loadDimensions"
import { MetricsResponseExtendedDto } from "domain/../../stories/msw/handlers/loadMetrics"
import React, { createContext, PropsWithChildren, useContext } from "react"
import { z } from "zod"
import { PeriodsContextProvider } from "../periods/PeriodsContext"
import { DashboardActionsProvider } from "./DashboardSettingsActions"
import { ServiceConfigs } from "shared/ServiceConfigs"
import type { CampaignSettingsResponseDTO } from "generated/models"
import { DashboardsSettingsControllerApi } from "generated/ui_reporting_apis"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import { Dimension, Metric } from "domain/ColumnConfigurator/types"
import MainContentAreaLoadingMask from "layout/MainLayout/Main/MainContentAreaLoadingMask"

export type DashboardSettingsContextProviderType = {
    baseId: string
    uuid: string
    campaign: CampaignSettingsResponseDTO
    dimensions: Dimension[]
    metrics: Metric[]
    settings: DashboardSettingsResponse
}

const DashboardSettingsContext = createContext<DashboardSettingsContextProviderType | undefined>(undefined)

type DashboardSettingsContextProviderProps = {
    baseId: string
    uuid?: string | undefined
    advertiserId: number
    campaignId: number
}

export const DashboardSettingsContextProvider = (props: PropsWithChildren<DashboardSettingsContextProviderProps>) => {
    const { baseId, uuid, advertiserId, campaignId, children } = props

    const {
        dataDefinitions: { dimensions, metrics },
    } = useReportingConfigurationContext()

    const dashboardsSettingsControllerApi = new DashboardsSettingsControllerApi(ServiceConfigs.uiReportingServiceConfig)

    const { data: campaignSettings, isSuccess: campaignSettingsSuccess } = useQuery({
        queryKey: ["campaignSettings", advertiserId, campaignId],
        queryFn: ({ signal }) => {
            return dashboardsSettingsControllerApi.dashboardsCampaignSettingsPost(
                { appContextDTO: { advertiserId, campaignId } },
                { signal: signal },
            )
        },
    })

    const { data: settings, isSuccess: settingsSuccess } = useQuery({
        queryKey: ["dashboardSettings", campaignSettings],
        queryFn: ({ signal }) => {
            return dashboardsSettingsControllerApi.dashboardsDashboardSettingsGet({ signal: signal })
        },
    })

    const isSuccess = campaignSettingsSuccess && settingsSuccess && metrics.size > 0 && dimensions.size > 0

    return (
        <DashboardSettingsContext.Provider
            value={{
                baseId: baseId,
                uuid: uuid,
                campaign: campaignSettings,
                settings: settings,
                metrics: Array.from(metrics.values()),
                dimensions: Array.from(dimensions.values()),
            }}
        >
            <>
                {!isSuccess && <MainContentAreaLoadingMask withAdditionalOffset={true} />}
                {isSuccess && (
                    <DashboardActionsProvider>
                        <PeriodsContextProvider>{children}</PeriodsContextProvider>
                    </DashboardActionsProvider>
                )}
            </>
        </DashboardSettingsContext.Provider>
    )
}

export const useDashboardSettings = () => {
    const context = useContext(DashboardSettingsContext)
    if (context === undefined) {
        throw new Error("useCampaignContext must be used within a CampaignContextProvider")
    }
    return context
}

const transformDimensions = (data: DimensionResponseExtendedDto) => {
    return Object.entries(data.data.dimensions).map(([key, value]) => ({
        identifier: key,
        ...value,
    }))
}

const transformMetrics = (data: MetricsResponseExtendedDto) => {
    return Object.entries(data.data.dataColumns).map(([key, value]) => ({
        identifier: key,
        ...value,
        round: z.coerce.number().parse(value.round),
    }))
}
