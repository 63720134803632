/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime"
import type { AppContextDTO, CampaignSettingsResponseDTO, DashboardSettingsDTO } from "../models/index"
import {
    AppContextDTOFromJSON,
    AppContextDTOToJSON,
    CampaignSettingsResponseDTOFromJSON,
    CampaignSettingsResponseDTOToJSON,
    DashboardSettingsDTOFromJSON,
    DashboardSettingsDTOToJSON,
} from "../models/index"

export interface DashboardsCampaignSettingsPostRequest {
    appContextDTO: AppContextDTO
}

/**
 *
 */
export class DashboardsSettingsControllerApi extends runtime.BaseAPI {
    /**
     */
    async dashboardsCampaignSettingsPostRaw(
        requestParameters: DashboardsCampaignSettingsPostRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<CampaignSettingsResponseDTO>> {
        if (requestParameters["appContextDTO"] == null) {
            throw new runtime.RequiredError(
                "appContextDTO",
                'Required parameter "appContextDTO" was null or undefined when calling dashboardsCampaignSettingsPost().',
            )
        }

        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        headerParameters["Content-Type"] = "application/json"

        const response = await this.request(
            {
                path: `/dashboards/campaignSettings`,
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: AppContextDTOToJSON(requestParameters["appContextDTO"]),
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignSettingsResponseDTOFromJSON(jsonValue))
    }

    /**
     */
    async dashboardsCampaignSettingsPost(
        requestParameters: DashboardsCampaignSettingsPostRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<CampaignSettingsResponseDTO> {
        const response = await this.dashboardsCampaignSettingsPostRaw(requestParameters, initOverrides)
        return await response.value()
    }

    /**
     */
    async dashboardsDashboardSettingsGetRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<DashboardSettingsDTO>> {
        const queryParameters: any = {}

        const headerParameters: runtime.HTTPHeaders = {}

        const response = await this.request(
            {
                path: `/dashboards/dashboardSettings`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        )

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardSettingsDTOFromJSON(jsonValue))
    }

    /**
     */
    async dashboardsDashboardSettingsGet(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<DashboardSettingsDTO> {
        const response = await this.dashboardsDashboardSettingsGetRaw(initOverrides)
        return await response.value()
    }
}
