import { FormApi, ReactFormApi, useForm, Validator } from "@tanstack/react-form"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { WidgetSettingsResponse } from "domain/../../stories/msw/handlers/widgetSettings"
import { WidgetMap, WidgetProps } from "domain/widget/WidgetRenderer"
import { nanoid } from "nanoid"
import * as api from "shared/service"
import React, { createContext, PropsWithChildren, useContext } from "react"
import { DashboardLayoutItemProps } from "./DashboardLayoutItem"
import { useGridStackIntegration } from "./useGridStackIntegration"
import MainContentAreaLoadingMask from "layout/MainLayout/Main/MainContentAreaLoadingMask"
import { type DashboardDTO } from "generated/models"
import { useDashboardSettings } from "domain/widget/DashboardSettings/DashboardSettingsContext"

type Form<TFormData, TFormValidator extends Validator<TFormData, unknown> | undefined = undefined> = FormApi<
    TFormData,
    TFormValidator
> &
    ReactFormApi<TFormData, TFormValidator>

type WidgetValues = Pick<WidgetSettingsResponse, "widgets">
type LayoutValues = Pick<WidgetSettingsResponse, "layout">
type WidgetForm = Form<WidgetValues>
type LayoutForm = Form<LayoutValues>

export type DashboardLayoutProviderType = ReturnType<typeof useGridStackIntegration> & {
    widgetForm: WidgetForm
    layoutForm: LayoutForm
    updateIsPending: boolean
    convertToLegacyIsPending: boolean
    onConvertToLegacy: () => void
    submit: () => void
    addWidget: <Type extends keyof WidgetMap>(
        widget: WidgetProps<Type>,
        options?: Partial<DashboardLayoutItemProps>,
    ) => void
}

const DashboardLayoutContext = createContext<DashboardLayoutProviderType | undefined>(undefined)

type GridStackConfig = {
    disableDrag?: true
    disableResize?: true
    isStatic?: true
}

type DashboardLayoutBaseProviderProps = GridStackConfig & {
    updateIsPending: boolean
    convertToLegacyIsPending: boolean
    widgetForm: WidgetForm
    layoutForm: LayoutForm
    onSubmit: (data: WidgetSettingsResponse) => void
    onConvertToLegacy: (data: WidgetSettingsResponse) => void
    setLayout: (value: DashboardLayoutItemProps[]) => void
    pushLayout: (value: DashboardLayoutItemProps) => void
}

export const DashboardLayoutBaseProvider = (props: PropsWithChildren<DashboardLayoutBaseProviderProps>) => {
    const {
        disableDrag,
        disableResize,
        isStatic,
        widgetForm,
        layoutForm,
        setLayout,
        pushLayout,
        children,
        onSubmit,
        onConvertToLegacy,
        updateIsPending,
        convertToLegacyIsPending,
    } = props

    const gridstackIntegration = useGridStackIntegration({
        onUpdate: setLayout,
        disableDrag,
        disableResize,
        isStatic,
    })

    const addWidget = <Type extends keyof WidgetMap>(
        widget: WidgetProps<Type>,
        options?: Partial<DashboardLayoutItemProps>,
    ) => {
        const id = nanoid(10)
        widgetForm.setFieldValue(`widgets.${id}`, { id, ...widget })
        pushLayout({
            id,
            h: 4,
            w: 4,
            x: 0,
            y: 0,
            ...options,
        })
    }

    return (
        <DashboardLayoutContext.Provider
            value={{
                ...gridstackIntegration,
                updateIsPending,
                convertToLegacyIsPending,
                widgetForm,
                layoutForm,
                addWidget,
                onConvertToLegacy: () => {
                    const layout = layoutForm.state.values.layout
                    const widgets = widgetForm.state.values.widgets
                    onConvertToLegacy({ layout, widgets })
                },
                submit: () => {
                    const layout = layoutForm.state.values.layout
                    const widgets = widgetForm.state.values.widgets
                    onSubmit({ layout, widgets })
                },
            }}
        >
            {children}
        </DashboardLayoutContext.Provider>
    )
}

const useDashboardLayoutForms = (data: WidgetSettingsResponse | undefined) => {
    const widgetForm = useForm<WidgetValues>({
        defaultValues: {
            widgets: data?.widgets ?? {},
        },
    })

    const layoutForm = useForm<LayoutValues>({
        defaultValues: {
            layout: data?.layout ?? [],
        },
    })
    return { widgetForm, layoutForm }
}

export const DashboardLayoutProvider = (props: PropsWithChildren<GridStackConfig>) => {
    const { children } = props
    const {
        baseId,
        campaign: { advertiserId, campaignId },
    } = useDashboardSettings()
    const queryClient = useQueryClient()

    const { data, isPending: dashboardViewRequestIsPending } = useQuery({
        queryKey: ["/api/reporting/dashboards/view", baseId],
        queryFn: () => {
            return api.get<WidgetSettingsResponse>(`/api/reporting/dashboards/view/${baseId}`)
        },
        staleTime: Infinity, // https://tkdodo.eu/blog/react-query-and-forms
    })

    const { mutate: save, isPending: updateIsPending } = useMutation({
        mutationKey: ["/api/reporting/dashboards/update", baseId, advertiserId, campaignId],
        mutationFn: (variables: WidgetSettingsResponse) => {
            return api.post<WidgetSettingsResponse>("/api/reporting/dashboards/update", {
                advertiserId: advertiserId,
                campaignId: campaignId,
                id: undefined,
                layout: variables.layout,
                widgets: variables.widgets,
            })
        },
        onSuccess: (data) => {
            queryClient.setQueryData(["/api/reporting/dashboards/view", baseId], data)
        },
    })

    /**
     * Trigger download dialog for the Blob object
     *
     * @param blob
     */
    const triggerDownloadDialog = (blob: Blob) => {
        // Generate a URL for the Blob object
        const fileURL = URL.createObjectURL(blob)

        // Create an anchor tag to trigger download
        const link = document.createElement("a")
        link.href = fileURL
        link.setAttribute("download", `${baseId}.json`)
        document.body.appendChild(link)
        link.click()

        // Clean up by removing the link and revoking the Blob URL
        link.parentNode?.removeChild(link)
        URL.revokeObjectURL(fileURL)
    }

    const { mutate: convertToLegacy, isPending: convertToLegacyIsPending } = useMutation({
        mutationKey: ["/api/reporting/dashboards/convertToLegacy", baseId, advertiserId, campaignId],
        mutationFn: (dashboard: WidgetSettingsResponse) => {
            return api.post<Blob>(
                "/api/reporting/dashboards/convertToLegacy",
                {
                    advertiserId: advertiserId,
                    campaignId: campaignId,
                    id: undefined,
                    layout: dashboard.layout,
                    widgets: dashboard.widgets,
                } satisfies DashboardDTO,
                { responseType: "blob" },
            )
        },
        onSuccess: triggerDownloadDialog,
    })

    const { widgetForm, layoutForm } = useDashboardLayoutForms(data)

    return (
        <DashboardLayoutBaseProvider
            {...props}
            updateIsPending={updateIsPending}
            convertToLegacyIsPending={convertToLegacyIsPending}
            onSubmit={save}
            onConvertToLegacy={convertToLegacy}
            layoutForm={layoutForm}
            widgetForm={widgetForm}
            setLayout={(items) => layoutForm.setFieldValue("layout", items)}
            pushLayout={(item) => layoutForm.pushFieldValue("layout", item)}
        >
            <>
                {dashboardViewRequestIsPending && <MainContentAreaLoadingMask withAdditionalOffset={true} />}
                {!dashboardViewRequestIsPending && children}
            </>
        </DashboardLayoutBaseProvider>
    )
}

export type DashboardLayoutNestedProviderProps = {
    layout: DashboardLayoutItemProps[]
    setLayout: (layout: DashboardLayoutItemProps[]) => void
    pushLayout: (layout: DashboardLayoutItemProps) => void
}

export const DashboardLayoutNestedProvider = (props: PropsWithChildren<DashboardLayoutNestedProviderProps>) => {
    const { layout, setLayout, pushLayout } = props

    const parentContext = useContext(DashboardLayoutContext)

    const { widgetForm: nestedWidgetForm, layoutForm: nestedLayoutForm } = useDashboardLayoutForms({
        widgets: {},
        layout,
    })
    const widgetForm = parentContext?.widgetForm ?? nestedWidgetForm
    const layoutForm = parentContext?.layoutForm ?? nestedLayoutForm

    return (
        <DashboardLayoutBaseProvider
            {...props}
            updateIsPending={false}
            convertToLegacyIsPending={false}
            onConvertToLegacy={() => {
                throw new Error("ConvertToLegacy cannot be called within a nested DashboardLayoutProvider")
            }}
            onSubmit={() => {
                throw new Error("Submit cannot be called within a nested DashboardLayoutProvider")
            }}
            layoutForm={layoutForm}
            widgetForm={widgetForm}
            setLayout={setLayout}
            pushLayout={pushLayout}
        />
    )
}

export const useDashboardLayout = () => {
    const context = useContext(DashboardLayoutContext)
    if (context === undefined) {
        throw new Error("useDashboardLayout must be used within a DashboardLayoutProvider")
    }
    return context
}

export const useHasParentDashboardLayout = () => {
    const context = useContext(DashboardLayoutContext)
    return context !== undefined
}
