/* router.js */
import React from "react"
import { Outlet, Route, Routes } from "react-router-dom"
import AdSpendOptimizer from "domain/adspend-optimizer/component/AdSpendOptimizer"
import AppContextFromQuery from "domain/core/component/AppContextFromQuery"
import PasswordResetRequestFormPage from "domain/password/PasswordResetRequestFormPage"
import PasswordResetConfirmationFormPage from "domain/password/PasswordResetConfirmationFormPage"
import LogonPage from "LogonPage"
import { AuthenticationState } from "domain/authentication/redux/authentication.slice"
import { getFirstMenuPath } from "shared/util/menu"
import { RootElementContextProvider } from "shared/component/layout/context/RootElementContext"
import { ArticleLayout } from "domain/content/ArticleLayout"
import MfaForm from "domain/onetimepassword/MfaForm"
import ConsentForm from "domain/consent/ConsentForm"
import { AuthLogin } from "login/AuthLogin"
import MainLayout from "layout/MainLayout/MainLayout"
import { ClassicUiEmbeddedMenuLeafDTO, MenuDTO, MenuNodeDTO } from "generated/models"
import { RootState } from "shared/redux/store"
import { ClassicUiEmbeddingContextProvider } from "shared/component/ClassicUiEmbeddingContextProvider"
import DashboardsEmbeddingAPI from "embedding/dashboards/DashboardsEmbeddingAPI"
import ClassicUiEmbeddingAPI from "embedding/classicui/ClassicUiEmbeddingAPI"
import { RequiredCampaignSelection } from "shared/component/layout/requiredcampaignselection/RequiredCampaignSelection"
import { NewsOverview } from "layout/MainLayout/UserMenu/Notification/NewsOverview"
import { PasswordExpirationDialog } from "domain/password/PasswordExpirationDialog"
import { useSelector } from "react-redux"
import { AppContextState } from "domain/core/redux/appcontext.slice"
import { RedirectTo } from "shared/component/RedirectTo"
import { PrivateRoute } from "shared/component"
import { SetDocumentTitle } from "shared/component/SetDocumentTitle"
import { AfterLoginNotification } from "domain/notification/AfterLoginNotification"
import { CopyToClipboardNotification } from "domain/copytoclipboard/CopyToClipboardNotification"
import UrlService from "shared/service/url.service"
import { NewUiPage } from "domain/core/component/NewUiPage"
import { ExportCenterPolling } from "layout/MainLayout/ExportCenter/ExportCenterPolling"
import {
    isASONewUiMenuLeaf,
    isClassicUiEmbeddedMenuLeaf,
    isDashboardsMenuLeaf,
    isLegacyDashboardsEmbeddedMenuLeaf,
    isNewUiMenuLeaf,
    MenuLeafDTO,
} from "domain/types"
import { isNode } from "shared/service/MenuUtil"
import { DashboardPage } from "domain/dashboard/DashboardPage"

type Props = {
    authentication: AuthenticationState
    menu: MenuDTO[]
}

const AppRouter: React.FunctionComponent<Props> = (props: Props): JSX.Element => {
    const FAILOVER_PATH = "/ui/failover"

    const appContextState: AppContextState = useSelector((state: RootState) => state.appContext)

    const extractClassicUIPaths = (menu: MenuDTO[]): ClassicUiEmbeddedMenuLeafDTO[] => {
        if (menu.length === 0) {
            return []
        }

        return (
            menu &&
            menu[0] &&
            menu
                .map((config) => {
                    if (isNode(config) && config.items !== undefined) {
                        return extractClassicUIPaths(config.items)
                    } else {
                        if (isClassicUiEmbeddedMenuLeaf(config)) {
                            return [config]
                        }

                        return []
                    }
                })
                .reduce((acc, curVal) => {
                    return acc.concat(curVal)
                }, [])
        )
    }

    /**
     * Creates private routes from the menu configurations
     *
     * @param menu
     * @param authentication
     */
    const createPrivateRoutesFromMenuConfiguration = (menu: MenuDTO[], authentication: AuthenticationState) => {
        return (
            menu &&
            menu[0] &&
            menu.map((config) => {
                switch (config.treeType) {
                    case "MenuNodeDTO":
                        return createPrivateRoutesFromMenuConfiguration((config as MenuNodeDTO).items, authentication)
                    case "NewUiMenuLeafDTO":
                    case "DashboardsMenuLeafDTO":
                    case "ASONewUiMenuLeafDTO":
                    case "LegacyDashboardsEmbeddedMenuLeafDTO":
                    case "ClassicUiEmbeddedMenuLeafDTO": {
                        let pageElement = null
                        if (isNewUiMenuLeaf(config)) {
                            pageElement = (
                                <AppContextFromQuery>
                                    <RootElementContextProvider>
                                        <NewUiPage pageConfiguration={config} />
                                    </RootElementContextProvider>
                                </AppContextFromQuery>
                            )
                        } else if (isASONewUiMenuLeaf(config)) {
                            pageElement = (
                                <AppContextFromQuery>
                                    <AdSpendOptimizer />
                                </AppContextFromQuery>
                            )
                        } else if (isLegacyDashboardsEmbeddedMenuLeaf(config)) {
                            pageElement = (
                                <DashboardsEmbeddingAPI
                                    key={
                                        "DashboardsEmbeddingAPI_" + config.dashboardGroup + "_" + config.baseDashboardId
                                    }
                                    url={UrlService.getDashboardApiUrl()}
                                    appContextDTO={appContextState.appContext}
                                    dashboardGroup={config.dashboardGroup}
                                    baseDashboardId={config.baseDashboardId}
                                />
                            )
                        } else if (isDashboardsMenuLeaf(config)) {
                            pageElement = <DashboardPage {...config} />
                        } else if (isClassicUiEmbeddedMenuLeaf(config)) {
                            pageElement = <></>
                        }

                        return (
                            <Route
                                key={config.path}
                                path={config.path}
                                element={
                                    <PrivateRoute authentication={authentication}>
                                        <RequiredCampaignSelection
                                            campaignSelectionRequired={
                                                (config as MenuLeafDTO).campaignSelectionRequired
                                            }
                                        />
                                        <SetDocumentTitle pageTitle={config.title}>{pageElement}</SetDocumentTitle>
                                    </PrivateRoute>
                                }
                            />
                        )
                    }
                    // default: {
                    //     return assertExhaustive(config)
                    // }
                }
            })
        )
    }

    const getFirsMenuEntryPathOrFailover = () => {
        return getFirstMenuPath(props.menu) ?? FAILOVER_PATH
    }

    return (
        <Routes>
            <Route element={<MainLayout />}>
                <Route
                    element={
                        <>
                            {/* Render always classic ui wrapper to avoid loading the whole classic ui app each time you change the menu entry */}
                            {/* ClassicUiContextProvider hides the classic ui wrapper if the url doesn't match some of the classic ui menu paths */}
                            <ClassicUiEmbeddingContextProvider entries={extractClassicUIPaths(props.menu)}>
                                <ClassicUiEmbeddingAPI
                                    url={UrlService.getClassicUiUrl()}
                                    appContextDTO={appContextState.appContext}
                                />
                            </ClassicUiEmbeddingContextProvider>
                            <ExportCenterPolling />
                            <NewsOverview />
                            <ArticleLayout />
                            <PasswordExpirationDialog />
                            <AfterLoginNotification />
                            <CopyToClipboardNotification />
                            <Outlet />
                        </>
                    }
                >
                    {/* Creates routes from menu json */}
                    {createPrivateRoutesFromMenuConfiguration(props.menu, props.authentication)}

                    {/* Redirect to the failover url in case of missing menu entries */}
                    <Route path={FAILOVER_PATH} element={<PrivateRoute authentication={props.authentication} />} />
                </Route>
            </Route>

            <Route
                path="/ui/login"
                element={
                    <SetDocumentTitle pageTitle="Login">
                        <AuthLogin />
                    </SetDocumentTitle>
                }
            />
            <Route
                path="/ui/password/request-reset"
                element={
                    <SetDocumentTitle pageTitle="Password reset">
                        <PasswordResetRequestFormPage />
                    </SetDocumentTitle>
                }
            />
            <Route
                path="/ui/password/reset/:token"
                element={
                    <SetDocumentTitle pageTitle="Password reset">
                        <PasswordResetConfirmationFormPage />
                    </SetDocumentTitle>
                }
            />

            <Route
                path="/ui/mfa/otp/verify-code"
                element={
                    <PrivateRoute authentication={props.authentication}>
                        <SetDocumentTitle pageTitle="Two-Factor Authentication">
                            <MfaForm />
                        </SetDocumentTitle>
                    </PrivateRoute>
                }
            />

            <Route
                path="/ui/consent"
                element={
                    <PrivateRoute authentication={props.authentication}>
                        <SetDocumentTitle pageTitle="Terms of Service">
                            <ConsentForm />
                        </SetDocumentTitle>
                    </PrivateRoute>
                }
            />

            <Route path="/ui/logon" element={<LogonPage />} />

            {/* if route not found, then redirect either to the first menu entry or to the /ui/logon */}
            <Route
                path="*"
                element={
                    <RedirectTo
                        predicate={props.authentication.loggedInViaSecondFactor}
                        successPath={getFirsMenuEntryPathOrFailover()}
                        errorPath={"/ui/logon"}
                    />
                }
            />
        </Routes>
    )
}

export default AppRouter
