import React, { ReactElement, useContext } from "react"
import { contextMenu as CM } from "react-contexify"
import {
    ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
    AdSpendOptimizerContext,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import { Col, ConfigProvider, Modal, Row, Table } from "antd"
import { ColumnConfigDTO } from "generated/models"
import ScenarioGridContextMenu from "domain/adspend-optimizer/component/scenario-grid/ScenarioGridContextMenu"
import ScenarioGridInlineMenu from "domain/adspend-optimizer/component/scenario-grid/ScenarioGridInlineMenu"
import { CONTEXT_MENU_FIELD_ID, customizeRenderEmpty } from "domain/datagrid/component/DataGrid"
import { v1 as uuid } from "uuid"
import GridUtil from "domain/widget/generic/GridUtil"
import DataGridService from "domain/datagrid/service/datagrid.service"
import { agnosticRenderer } from "shared/component/renderers/renderers"
import { AntTableColumn, GridDataRowDTO } from "domain/types"

/**
 * Renders our default header layout for non sortable columns
 */
const defaultHeader = (label: string): ReactElement => {
    return (
        <span key={uuid()} data-tip={label} data-force-tooltip={true}>
            {label}
        </span>
    )
}

type ScenarioGridType = {
    dataRows: GridDataRowDTO[]
    showGrid: number
}

export default (scenarioGridType: ScenarioGridType) => {
    const asoContext = useContext(AdSpendOptimizerContext)

    type InlineAction = {
        icon: string
        action: { identifier: "SELECT" | "EDIT" | "DELETE"; name: string }
        item: { id: number }
    }

    const invokeAction = (button: InlineAction) => {
        switch (button.action.identifier) {
            case "SELECT":
                onSelect(button.item.id)
                break
            case "EDIT":
                onEdit(button.item.id)
                break
            case "DELETE":
                onDelete(button.item.id)
                break
        }
    }

    const onSelect = (id: number | string) => {
        const select = (scenarioId: number | string) =>
            scenarioGridType.showGrid === 1
                ? asoContext.selectFirstScenario(scenarioId)
                : asoContext.selectSecondScenario(scenarioId)
        asoContext && select(id)
    }

    const onEdit = (id: number) => {
        asoContext && asoContext.toggleScenarioForm(null, id)
    }

    const onDelete = (id: number) => {
        const scenario = asoContext.allScenarios.find((s) => s.adspend_scenario_id === id)
        Modal.confirm({
            title: "Please Confirm Deletion",
            content: `Are you sure you want to delete '${scenario ? scenario.adspend_scenario_name : id}'?`,
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
                if (id !== undefined) {
                    asoContext && asoContext.deleteScenario(id)
                }
            },
        })
    }

    const inlineMenu = (entry: GridDataRowDTO) => {
        const buttons: InlineAction[] = [
            {
                icon: "check",
                action: { identifier: "SELECT", name: "Select this Scenario for Comparison" },
                item: { id: entry?.value as number },
            },
            {
                icon: "edit",
                action: { identifier: "EDIT", name: "Edit" },
                item: { id: entry?.value as number },
            },
            {
                icon: "delete",
                action: { identifier: "DELETE", name: "Delete" },
                item: { id: entry?.value as number },
            },
        ]
        return <ScenarioGridInlineMenu buttons={buttons} handleAction={invokeAction} />
    }

    const columns = asoContext.scenarioGridColumns
        ? GridUtil.mapColumns(
              asoContext.scenarioGridColumns,
              (column: ColumnConfigDTO) => {
                  return column.columnIdentifier === CONTEXT_MENU_FIELD_ID
                      ? inlineMenu
                      : column.gridColumnProperties.renderer
                        ? agnosticRenderer(column)
                        : null
              },
              (column: ColumnConfigDTO) => {
                  return defaultHeader(column.gridColumnProperties.columnHeader)
              },
              (column: AntTableColumn) => () => ({}),
          )
        : []
    const rows = scenarioGridType.dataRows ? scenarioGridType.dataRows.map((s) => ({ ...s }) as GridDataRowDTO) : []

    const onRow = (row, rowIndex) => {
        return {
            onContextMenu: (event) => {
                event.preventDefault()

                CM.show({
                    id: "scenario-grid-context-menu",
                    event: event,
                    props: { item: row[`${ADSPEND_SCENARIO_DIMENSION_IDENTIFIER}.value`]?.value },
                })
            },
            onDoubleClick: (event) => {
                event.preventDefault()
                onSelect(row[`${ADSPEND_SCENARIO_DIMENSION_IDENTIFIER}.value`]?.value)
            },
        }
    }

    const data = rows.map((row, index) => ({ ...DataGridService.convertRowToTableStructure(row), key: index }))
    return (
        <div className={"scenario-grid"}>
            {scenarioGridType.showGrid > 0 && (
                <Row>
                    <ScenarioGridContextMenu
                        menuId={"scenario-grid-context-menu"}
                        handleSelect={onSelect}
                        handleEdit={onEdit}
                        handleDelete={onDelete}
                    />
                    <Col>
                        <ConfigProvider renderEmpty={customizeRenderEmpty}>
                            {/* TODO: replace by the new grid */}
                            <Table
                                className={"ant-table ant-table-small data-grid-table data-grid-selectable"}
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                                onRow={onRow}
                                onHeaderRow={(columns) => {
                                    return { className: "ant-table-thead" }
                                }}
                            />
                        </ConfigProvider>
                    </Col>
                </Row>
            )}
        </div>
    )
}
