/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime"
import type { CampaignSettingsDTO } from "./CampaignSettingsDTO"
import {
    CampaignSettingsDTOFromJSON,
    CampaignSettingsDTOFromJSONTyped,
    CampaignSettingsDTOToJSON,
} from "./CampaignSettingsDTO"

/**
 *
 * @export
 * @interface CampaignSettingsResponseDTO
 */
export interface CampaignSettingsResponseDTO {
    /**
     *
     * @type {number}
     * @memberof CampaignSettingsResponseDTO
     */
    advertiserId: number
    /**
     *
     * @type {number}
     * @memberof CampaignSettingsResponseDTO
     */
    campaignId: number
    /**
     *
     * @type {CampaignSettingsDTO}
     * @memberof CampaignSettingsResponseDTO
     */
    settings: CampaignSettingsDTO
}

/**
 * Check if a given object implements the CampaignSettingsResponseDTO interface.
 */
export function instanceOfCampaignSettingsResponseDTO(value: object): value is CampaignSettingsResponseDTO {
    if (!("advertiserId" in value) || value["advertiserId"] === undefined) return false
    if (!("campaignId" in value) || value["campaignId"] === undefined) return false
    if (!("settings" in value) || value["settings"] === undefined) return false
    return true
}

export function CampaignSettingsResponseDTOFromJSON(json: any): CampaignSettingsResponseDTO {
    return CampaignSettingsResponseDTOFromJSONTyped(json, false)
}

export function CampaignSettingsResponseDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CampaignSettingsResponseDTO {
    if (json == null) {
        return json
    }
    return {
        advertiserId: json["advertiserId"],
        campaignId: json["campaignId"],
        settings: CampaignSettingsDTOFromJSON(json["settings"]),
    }
}

export function CampaignSettingsResponseDTOToJSON(value?: CampaignSettingsResponseDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        advertiserId: value["advertiserId"],
        campaignId: value["campaignId"],
        settings: CampaignSettingsDTOToJSON(value["settings"]),
    }
}
