import { DashboardSettingsContextProvider } from "domain/widget/DashboardSettings/DashboardSettingsContext"
import { DashboardLayoutProvider, useDashboardLayout } from "domain/dashboard/DashboardLayout/DashboardLayoutProvider"
import { Button, Stack } from "@mui/material"
import { DashboardLayoutGrid } from "domain/dashboard/DashboardLayout/DashboardLayoutGrid"
import React from "react"
import AddWidgetModal from "domain/dashboard/DashboardLayout/AddWidgetModal"
import LoadingButton from "@mui/lab/LoadingButton"
import { DashboardsMenuLeafDTO } from "generated/models"

export const DashboardPage = (props: DashboardsMenuLeafDTO) => {
    const { baseDashboardId } = props
    return (
        <DashboardSettingsContextProvider
            key={baseDashboardId}
            baseId={baseDashboardId}
            // uuid={"some-dashboard-uuid"}
            advertiserId={2973}
            campaignId={825}
        >
            <DashboardLayoutProvider>
                <Stack gap={2}>
                    <DashboardLayoutActions />
                    <DashboardLayoutGrid />
                </Stack>
            </DashboardLayoutProvider>
        </DashboardSettingsContextProvider>
    )
}

const DashboardLayoutActions = () => {
    const {
        toggleDrag,
        toggleResize,
        submit,
        onConvertToLegacy,
        updateIsPending,
        convertToLegacyIsPending,
        isDraggable,
        isResizable,
    } = useDashboardLayout()
    return (
        <Stack direction="row" gap={1} justifyContent="flex-end">
            <Button variant={isResizable ? "outlined" : "contained"} onClick={toggleResize}>
                Toggle resize
            </Button>
            <Button variant={isDraggable ? "outlined" : "contained"} onClick={toggleDrag}>
                Toggle drag
            </Button>
            <AddWidgetModal />
            <LoadingButton variant="contained" onClick={submit} loading={updateIsPending}>
                Save (WIP)
            </LoadingButton>
            <LoadingButton variant="contained" onClick={onConvertToLegacy} loading={convertToLegacyIsPending}>
                Download json
            </LoadingButton>
        </Stack>
    )
}
