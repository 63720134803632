import moment, { Moment } from "moment"
import React, { createContext, PropsWithChildren, Suspense, useCallback, useContext, useState } from "react"
import { getPreviousPeriodExact } from "utils/time-range-utils"

export type TimeRange = {
    start: Moment
    end: Moment
}

type PeriodsContextProviderType = {
    period: TimeRange
    previousPeriod: TimeRange
    setPeriod: React.Dispatch<React.SetStateAction<TimeRange>>
    setPreviousPeriod: React.Dispatch<React.SetStateAction<TimeRange>>
}

const PeriodsContext = createContext<PeriodsContextProviderType | undefined>(undefined)

type PeriodsContextProviderProps = NonNullable<unknown>

const previousMonth = moment().subtract(7, "month")
const prevMonthRange = {
    start: previousMonth.clone().startOf("month"),
    end: previousMonth.clone().endOf("month"),
}
const last30Days = {
    start: moment().subtract(30, "days"),
    end: moment().subtract(1, "days"),
}

export const PeriodsContextProvider = (props: PropsWithChildren<PeriodsContextProviderProps>) => {
    const { children } = props
    const [period, setPeriod] = useState<TimeRange>(last30Days)
    const [previousPeriod, setPreviousPeriod] = useState<TimeRange>(getPreviousPeriodExact(period.start, period.end))
    const [hasPreviousPeriodChanged, setHasPreviousPeriodChanged] = useState(false)

    const setPeriodSync: React.Dispatch<React.SetStateAction<TimeRange>> = useCallback(
        (value) => {
            const newRange = fallBackTimeRange(typeof value === "function" ? value(period) : value)
            setPeriod(newRange)
            if (!hasPreviousPeriodChanged) {
                setPreviousPeriod(getPreviousPeriodExact(newRange.start, newRange.end))
            }
        },
        [hasPreviousPeriodChanged, period, setPreviousPeriod, setPeriod],
    )

    /**
     * Fallback to the opposite value if one of the values is missing
     *
     * @param timeRange
     */
    const fallBackTimeRange = (timeRange: TimeRange): TimeRange => {
        return {
            start: timeRange.start ?? timeRange.end,
            end: timeRange.end ?? timeRange.start,
        }
    }

    const setPreviousPeriodSync: React.Dispatch<React.SetStateAction<TimeRange>> = useCallback((value) => {
        setPreviousPeriod(value)
        setHasPreviousPeriodChanged(true)
    }, [])

    return (
        <PeriodsContext.Provider
            value={{
                period,
                previousPeriod: previousPeriod,
                setPeriod: (value) => {
                    setPeriodSync(value)
                },
                setPreviousPeriod: (value) => {
                    setPreviousPeriodSync(value)
                },
            }}
        >
            <Suspense>{children}</Suspense>
        </PeriodsContext.Provider>
    )
}

export const usePeriodsContext = () => {
    const context = useContext(PeriodsContext)
    if (context === undefined) {
        throw new Error("usePeriodsContext must be used within a PeriodsContextProvider")
    }
    return context
}
