import { DateRange, PickersShortcutsItem } from "@mui/x-date-pickers-pro"
import moment, { Moment } from "moment"

export const shortCutDateRanges = [
    {
        label: "Yesterday",
        getValue: () => {
            const yesterday = moment().subtract(1, "day")
            return [yesterday.clone(), yesterday.clone()]
        },
    },
    {
        label: "This quarter",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("quarter"), today.clone().endOf("quarter")]
        },
    },
    {
        label: "This month",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("month"), today.clone().endOf("month")]
        },
    },
    {
        label: "This Week",
        getValue: () => {
            const today = moment()
            return [today.clone().startOf("week"), today.clone().endOf("week")]
        },
    },
    {
        label: "Previous quarter",
        getValue: () => {
            const previousQuarter = moment().subtract(3, "months")
            return [previousQuarter.clone().startOf("quarter"), previousQuarter.clone().endOf("quarter")]
        },
    },
    {
        label: "Previous month",
        getValue: () => {
            const previousMonth = moment().subtract(1, "month")
            return [previousMonth.clone().startOf("month"), previousMonth.clone().endOf("month")]
        },
    },
    {
        label: "Previous week",
        getValue: () => {
            const previousWeek = moment().subtract(1, "week")
            return [previousWeek.clone().startOf("week"), previousWeek.clone().endOf("week")]
        },
    },
    {
        label: "Reset",
        getValue: () => [null, null],
    },
] satisfies PickersShortcutsItem<DateRange<Moment>>[]

export const getLabelFromDateRange = (start: Moment, end: Moment) => {
    for (const item of shortCutDateRanges) {
        const [rangeStart, rangeEnd] = item.getValue()

        if (!rangeStart || !rangeEnd) {
            continue
        }

        if (start.isSame(rangeStart, "day") && end.isSame(rangeEnd, "day")) {
            return item.label
        }
    }

    return `${start.format("DD.MM.YYYY")} - ${end.format("DD.MM.YYYY")}`
}

// Add previous period timerange picker that updates when not dirties

export const getPreviousPeriod = (start: Moment, end: Moment) => {
    const periodUnits = ["year", "quarter", "month", "week"] as const
    let previousStartDate, previousEndDate

    /**
     * Check periods first because just jumping back the same period duration
     * can lead to a few inconsistencies because of leap years and February being 28 days
     **/
    for (const unit of periodUnits) {
        if (start.isSame(start.clone().startOf(unit)) && end.isSame(end.clone().endOf(unit))) {
            previousStartDate = start.clone().subtract(1, unit).startOf(unit)
            previousEndDate = previousStartDate.clone().endOf(unit)
            return [previousStartDate, previousEndDate]
        }
    }

    getPreviousPeriodExact(start, end)
}

export const getPreviousPeriodExact = (start: Moment, end: Moment) => {
    const periodLengthInDays = end.diff(start, "days") + 1
    const previousStartDate = start.clone().subtract(periodLengthInDays, "days")
    const previousEndDate = end.clone().subtract(periodLengthInDays, "days")
    return { start: previousStartDate, end: previousEndDate }
}
