/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime"
/**
 *
 * @export
 * @interface AppContextDTO
 */
export interface AppContextDTO {
    /**
     *
     * @type {number}
     * @memberof AppContextDTO
     */
    advertiserId?: number
    /**
     *
     * @type {string}
     * @memberof AppContextDTO
     */
    advertiserName?: string
    /**
     *
     * @type {number}
     * @memberof AppContextDTO
     */
    campaignId?: number
    /**
     *
     * @type {string}
     * @memberof AppContextDTO
     */
    campaignName?: string
    /**
     *
     * @type {number}
     * @memberof AppContextDTO
     */
    lineItemId?: number
    /**
     *
     * @type {string}
     * @memberof AppContextDTO
     */
    lineItemName?: string
    /**
     *
     * @type {number}
     * @memberof AppContextDTO
     */
    subCampaignId?: number
    /**
     *
     * @type {string}
     * @memberof AppContextDTO
     */
    subCampaignName?: string
}

/**
 * Check if a given object implements the AppContextDTO interface.
 */
export function instanceOfAppContextDTO(value: object): value is AppContextDTO {
    return true
}

export function AppContextDTOFromJSON(json: any): AppContextDTO {
    return AppContextDTOFromJSONTyped(json, false)
}

export function AppContextDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppContextDTO {
    if (json == null) {
        return json
    }
    return {
        advertiserId: json["advertiserId"] == null ? undefined : json["advertiserId"],
        advertiserName: json["advertiserName"] == null ? undefined : json["advertiserName"],
        campaignId: json["campaignId"] == null ? undefined : json["campaignId"],
        campaignName: json["campaignName"] == null ? undefined : json["campaignName"],
        lineItemId: json["lineItemId"] == null ? undefined : json["lineItemId"],
        lineItemName: json["lineItemName"] == null ? undefined : json["lineItemName"],
        subCampaignId: json["subCampaignId"] == null ? undefined : json["subCampaignId"],
        subCampaignName: json["subCampaignName"] == null ? undefined : json["subCampaignName"],
    }
}

export function AppContextDTOToJSON(value?: AppContextDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        advertiserId: value["advertiserId"],
        advertiserName: value["advertiserName"],
        campaignId: value["campaignId"],
        campaignName: value["campaignName"],
        lineItemId: value["lineItemId"],
        lineItemName: value["lineItemName"],
        subCampaignId: value["subCampaignId"],
        subCampaignName: value["subCampaignName"],
    }
}
