import React, { useContext, useEffect, useState } from "react"
import AdSpendOptimizerProvider, {
    AdSpendOptimizerContext,
} from "domain/adspend-optimizer/context/AdSpendOptimizerContext"
import { connect } from "react-redux"
import { AppContextDTO } from "generated/models"
import { Button, Col, Modal, Row, Spin } from "antd"
import { LoadingOutlined, OrderedListOutlined, PlusOutlined } from "@ant-design/icons"
import ScenarioGrid from "domain/adspend-optimizer/component/scenario-grid/ScenarioGrid"
import ScenarioComparisonGrid from "domain/adspend-optimizer/component/comparison-grid/ScenarioComparisonGrid"
import { Pagination } from "shared/component/ant/Pagination"
import { Polling } from "shared/component"
import AdSpendOptimizerService from "domain/adspend-optimizer/service/adspend-optimizer.service"
import { ContentBox } from "domain/core/component/ContentBox"
import { MultiBarChart } from "domain/adspend-optimizer/component/chart/MultiBarChart"
import { StackedBarChart } from "domain/adspend-optimizer/component/chart/StackedBarChart"
import { HelpBubble } from "domain/core/component/HelpBubble"
import { PageErrorMessage } from "domain/core/component/PageErrorMessage"
import { ScenarioOverview } from "domain/adspend-optimizer/component/overview/ScenarioOverview"
import ScenarioComparisonHeader from "domain/adspend-optimizer/component/comparison-grid/ScenarioComparisonGridHeader"
import { capitalizeEnum } from "shared/util/util"
import { MediaPlanEfficiencyWrapper } from "domain/adspend-optimizer/component/efficiency"
import ScenarioForm from "domain/adspend-optimizer/component/scenario-form/ScenarioForm"
import { DataGridContextProvider } from "domain/widget/generic/DataGridContext"
import { selectors as appcontextSelectors } from "domain/core/redux/appcontext.slice"
import { log } from "shared/util/log"

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 24 }} spin />)

const AboutAdspendOptimizer = () => (
    <HelpBubble title={"About the AdSpend Optimizer"}>
        <div>
            <p>
                The AdSpend Optimizer provides forecasts for future conversions, total price, and efficiency metrics.
                These forecasts will help you optimize your budget across channels and sub campaigns.
            </p>
            <p>
                You can create your <strong>own future budget scenarios</strong> or use our{" "}
                <strong>pre-computed recommended scenario</strong>, which is based your historical average budget.
            </p>
            <p>
                The tool is oriented around comparisons, either against historical performance or two different future
                scenarios to enable you to pick the outcome that is right for your business.
            </p>
        </div>
    </HelpBubble>
)

type Props = {
    appContext?: AppContextDTO
}

const AdSpendOptimizer = (props: Props) => {
    const asoContext = useContext(AdSpendOptimizerContext)

    const [appBusy, setAppBusy] = useState(false)
    const [formBusy, setFormBusy] = useState(false)

    const campaignName = asoContext && asoContext.campaignData ? asoContext.campaignData.campaignName : ""
    const formattedOptimizationLevel = asoContext
        ? capitalizeEnum(asoContext.comparisonOptimizationLevel).replace("-", " ")
        : ""

    useEffect(() => {
        if (props.appContext && props.appContext.campaignId) {
            asoContext.setAppContext(props.appContext)
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.appContext])

    useEffect(() => {
        setAppBusy(
            !(
                asoContext &&
                asoContext.appContext &&
                asoContext.allScenarios &&
                asoContext.comparisonData &&
                !asoContext.userNotAuthorized
            ),
        )
    }, [asoContext])

    useEffect(() => {
        setFormBusy(asoContext.formBusy || false)
    }, [asoContext.formBusy])

    const selectScenario1 = (_, value) => asoContext.selectFirstScenario(value)
    const selectScenario2 = (_, value) => asoContext.selectSecondScenario(value)

    return (
        <React.Fragment>
            <div className="datagrid-body adspend-optimizer">
                <div className={"title-bar"}>
                    <div className={"title-container"}>
                        <h3 className="widget-title">
                            AdSpend Optimization Results for <em>{campaignName}</em>
                        </h3>
                    </div>
                    <div className={"button-container"}>
                        <Button
                            htmlType="button"
                            size={"large"}
                            className={"button-scenario-list"}
                            onClick={() => asoContext.toggleScenarioGrid(2)}
                            disabled={appBusy}
                            style={{ marginRight: 5 }}
                        >
                            <OrderedListOutlined />
                            {campaignName + " Scenarios"}
                        </Button>

                        <Button
                            type={"primary"}
                            size={"large"}
                            htmlType="button"
                            className={"button-scenario-create"}
                            onClick={asoContext.toggleScenarioForm}
                            disabled={appBusy}
                        >
                            <PlusOutlined />
                            Create Scenario
                        </Button>
                        <AboutAdspendOptimizer />
                    </div>
                </div>

                {asoContext && asoContext.userNotAuthorized && (
                    <ContentBox>
                        <PageErrorMessage type={"info"} title={"Could not Access the AdSpend Optimizer"}>
                            We’re sorry, but the campaign you've selected or your user account lack permission to the
                            AdSpend Optimizer.
                            <p>
                                We do not want you to be left out, so please contact your account manager to find out
                                how you can get access to this tool that will let you forecast future conversions and
                                total prices based on your budget scenarios.
                            </p>
                        </PageErrorMessage>
                    </ContentBox>
                )}

                {asoContext && !asoContext.userNotAuthorized && (
                    <React.Fragment>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-start",
                            }}
                        >
                            <Modal
                                visible={asoContext && asoContext.showScenarioForm && !asoContext.scenarioToEdit}
                                style={{ top: 30 }}
                                title={"Add Scenario"}
                                width={720}
                                onCancel={asoContext.toggleScenarioForm}
                                onOk={() => asoContext.submitForm()}
                                okText="Save"
                                okButtonProps={{
                                    disabled: asoContext.invokeFormSubmit,
                                    icon: asoContext.invokeFormSubmit ? <LoadingOutlined /> : undefined,
                                }}
                                cancelButtonProps={{ disabled: asoContext.invokeFormSubmit }}
                                destroyOnClose={true}
                                className={"adspend-modal"}
                                maskClosable={false}
                            >
                                <div>
                                    <Spin spinning={formBusy}>
                                        {props.appContext && (
                                            <ScenarioForm
                                                mode="ADD"
                                                campaignId={props.appContext.campaignId}
                                                campaignName={campaignName}
                                                channelData={asoContext.channelData}
                                                subCampaignData={asoContext.subCampaignData}
                                            />
                                        )}
                                    </Spin>
                                </div>
                            </Modal>
                        </div>

                        <Modal
                            visible={
                                asoContext &&
                                asoContext.scenarioGridColumns &&
                                asoContext.scenarios &&
                                asoContext.showGrid > 0
                            }
                            title={
                                <React.Fragment>
                                    <em>{campaignName}</em> Scenarios
                                </React.Fragment>
                            }
                            style={{ top: 30 }}
                            onCancel={asoContext.hideScenarioGrid}
                            footer={null}
                            width={"1000px"}
                            maskClosable={false}
                            zIndex={20}
                            destroyOnClose={true}
                        >
                            <ScenarioGrid showGrid={asoContext.showGrid} dataRows={asoContext.scenarios} />
                            {asoContext.showGrid > 0 && (
                                <Pagination
                                    page={asoContext.scenarioGridPagination.page}
                                    pageSize={asoContext.scenarioGridPagination.pageSize}
                                    totalEntities={asoContext.scenarioGridPagination.totalEntities}
                                    pageSizeOptions={["50", "100"]}
                                    onPageChange={asoContext.handlePageChange}
                                />
                            )}
                        </Modal>

                        <Modal
                            visible={asoContext && asoContext.showScenarioForm && !!asoContext.scenarioToEdit}
                            style={{ top: 30 }}
                            title={"Edit Scenario"}
                            width={720}
                            onCancel={asoContext.toggleScenarioForm}
                            onOk={() => asoContext.submitForm()}
                            okText="Save"
                            okButtonProps={{
                                disabled: asoContext.invokeFormSubmit,
                                icon: asoContext.invokeFormSubmit ? <LoadingOutlined /> : undefined,
                            }}
                            cancelButtonProps={{ disabled: asoContext.invokeFormSubmit }}
                            destroyOnClose={true}
                            className={"adspend-modal"}
                            maskClosable={false}
                        >
                            <Spin spinning={formBusy}>
                                {props.appContext && asoContext.scenarioToEdit && (
                                    <ScenarioForm
                                        mode="EDIT"
                                        campaignId={props.appContext.campaignId}
                                        campaignName={campaignName}
                                        scenario={asoContext.scenarioToEdit}
                                        channelData={asoContext.channelData}
                                        subCampaignData={asoContext.subCampaignData}
                                    />
                                )}
                            </Spin>
                        </Modal>

                        {asoContext &&
                            asoContext.scenariosToPoll &&
                            asoContext.scenariosToPoll.length > 0 &&
                            asoContext.scenariosToPoll.map((id) => {
                                return (
                                    <Polling
                                        key={id}
                                        api={() =>
                                            AdSpendOptimizerService.fetchScenario(id as number, asoContext.appContext)
                                        }
                                        onError={() => log.warn("error polling")}
                                        onData={asoContext.updateScenario}
                                        ms={10000}
                                    />
                                )
                            })}

                        <Spin spinning={appBusy}>
                            <ScenarioOverview />

                            {asoContext &&
                                asoContext.comparisonData &&
                                asoContext.comparisonData.rows &&
                                asoContext.comparisonData.rows.length > 0 && (
                                    <React.Fragment>
                                        <ScenarioComparisonHeader
                                            optimizationLevel={asoContext.comparisonOptimizationLevel}
                                            scenarios={asoContext.allScenarios}
                                            callback1={selectScenario1}
                                            callback2={selectScenario2}
                                            scenarioId1={asoContext.scenario1}
                                            scenarioId2={asoContext.scenario2}
                                            titlePrefix={"Budget Shifts per"}
                                        />
                                        <ContentBox>
                                            <Col span={24} style={{ textAlign: "center" }}>
                                                <h3>Total Budget per {formattedOptimizationLevel} </h3>
                                            </Col>

                                            <MultiBarChart
                                                data={asoContext.comparisonData}
                                                scenario1={asoContext.scenario1data}
                                                scenario2={asoContext.scenario2data}
                                            />

                                            {asoContext &&
                                                asoContext.weeklyComparisonData &&
                                                asoContext.weeklyComparisonData.rows &&
                                                asoContext.weeklyComparisonData.rows.length > 0 && (
                                                    <React.Fragment>
                                                        <Row justify="center" style={{ marginTop: 85 }}>
                                                            <Col span={24}>
                                                                <Row>
                                                                    <Col span={12} style={{ textAlign: "center" }}>
                                                                        <h3>
                                                                            {
                                                                                asoContext.scenario1data
                                                                                    .adspend_scenario_name
                                                                            }
                                                                            : Weekly Budget per{" "}
                                                                            {formattedOptimizationLevel}
                                                                        </h3>
                                                                    </Col>
                                                                    <Col span={12} style={{ textAlign: "center" }}>
                                                                        <h3>
                                                                            {
                                                                                asoContext.scenario2data
                                                                                    .adspend_scenario_name
                                                                            }
                                                                            : Weekly Budget per{" "}
                                                                            {formattedOptimizationLevel}
                                                                        </h3>
                                                                    </Col>
                                                                </Row>
                                                                <StackedBarChart
                                                                    data={asoContext.weeklyComparisonData}
                                                                    scenario1={asoContext.scenario1data}
                                                                    scenario2={asoContext.scenario2data}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                        </ContentBox>
                                    </React.Fragment>
                                )}

                            {asoContext &&
                                asoContext.comparisonData &&
                                asoContext.comparisonData.rows &&
                                asoContext.comparisonData.rows.length > 0 && (
                                    <React.Fragment>
                                        <ScenarioComparisonHeader
                                            optimizationLevel={asoContext.comparisonOptimizationLevel}
                                            scenarios={asoContext.allScenarios}
                                            callback1={selectScenario1}
                                            callback2={selectScenario2}
                                            scenarioId1={asoContext.scenario1}
                                            scenarioId2={asoContext.scenario2}
                                            onDownload={asoContext.downloadExcel}
                                            downloadTooltip="Download Scenario data as Excel"
                                            titlePrefix={"Shifts per"}
                                        />

                                        <ContentBox className={"datagrid-widget scenario-comparison-grid-wrapper"}>
                                            <DataGridContextProvider>
                                                <ScenarioComparisonGrid
                                                    data={asoContext.comparisonData}
                                                    requestData={asoContext.comparisonRequestData}
                                                    optimizationLevel={asoContext.comparisonOptimizationLevel}
                                                    scenario1Name={asoContext.scenario1data.adspend_scenario_name}
                                                    scenario2Name={asoContext.scenario2data.adspend_scenario_name}
                                                />
                                            </DataGridContextProvider>
                                        </ContentBox>
                                    </React.Fragment>
                                )}

                            {asoContext && asoContext.channelData && asoContext.subCampaignData && (
                                <MediaPlanEfficiencyWrapper
                                    mediaPlanType={asoContext.comparisonOptimizationLevel.toLowerCase()}
                                />
                            )}
                        </Spin>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        appContext: appcontextSelectors.getAppContext(state),
    }
}

const AdSpendOptimizerWithState = connect(mapStateToProps)(AdSpendOptimizer)

export default (props) => (
    <AdSpendOptimizerProvider>
        <AdSpendOptimizerWithState {...props} />
    </AdSpendOptimizerProvider>
)
