import { UpdaterFn } from "@tanstack/react-form"
import React, { ComponentProps, lazy } from "react"

export type WidgetSettings<Settings extends NonNullable<unknown>> = {
    settings: Settings
    setSettings?: UpdaterFn<Settings, void>
}

const WidgetMap = {
    "text-widget": lazy(() => import("../widget/TextWidget/TextWidget")),
    "multi-bar-chart": lazy(() => import("./BarChart/MultiBarChart")),
    "time-based-bar-chart": lazy(() => import("./BarChart/TimeBasedBarChart")),
    "line-chart": lazy(() => import("./LineChart/LineChartWidget")),
    "tabbed-widget-chart": lazy(() => import("./TabbedWidgetChart/TabbedWidgetChart")),
    period: lazy(() => import("./periods/PeriodWidget")),
    "previous-period": lazy(() => import("./periods/PreviousPeriodWidget")),
    "metric-toggle": lazy(() => import("./DashboardSettings/MetricToggleWidget")),
    "reporting-method-toggle": lazy(() => import("./DashboardSettings/ReportingMethodToggleWidget")),
    "reporting-dimension-toggle": lazy(() => import("./DashboardSettings/ReportingDimensionToggleWidget")),
} satisfies Record<string, React.LazyExoticComponent<(props: WidgetSettings<any>) => JSX.Element>>

export type WidgetMap = typeof WidgetMap

export type WidgetProps<Type extends keyof WidgetMap, Additional extends Record<string, any> = NonNullable<unknown>> = {
    [K in Type]: {
        type: Type
    } & ComponentProps<WidgetMap[K]> &
        Additional
}[Type]

export type WidgetPropsArray<
    Types extends keyof WidgetMap = keyof WidgetMap,
    Additional extends Record<string, any> = NonNullable<unknown>,
> = Array<
    {
        [Type in Types]: WidgetProps<Type, Additional>
    }[Types]
>

export const WidgetRenderer = <Type extends keyof typeof WidgetMap>(props: WidgetProps<Type>) => {
    if (props.type) {
        const Widget = WidgetMap[props.type]

        // TypeScript isn't smart enough but at usage it is checked correctly
        return <Widget {...(props as any)} />
    } else {
        return <React.Fragment />
    }
}
